/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { rhythm } from '../utils/typography'
import avatar from '../images/avatar.png'
import { GitHubButton } from './GitHubButton'
import { TwitterButton } from './TwitterButton'
import { PolyworkButton } from './PolyworkButton'

const Bio = ({
  title,
  author,
  description,
  githubUsername,
  twitterUsername,
  polyworkUsername,
}) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'start',
      padding: '1rem 0 0 0',
    }}
  >
    <img
      src={avatar}
      alt={author}
      css={{
        marginRight: rhythm(0.75),
        marginBottom: 0,
        width: rhythm(2),
        height: rhythm(2),
        borderRadius: '50%',
      }}
    />

    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1 css={{ margin: 0, fontSize: '1.5rem' }}>{title}</h1>

      <p css={{ marginBottom: 0, color: 'rgb(0 0 0 / 50%)' }}>{description}</p>

      <ul
        css={{
          display: 'flex',
          margin: 0,
          marginTop: '.5rem',
          listStyle: 'none',
        }}
      >
        <li css={{ margin: 0, marginRight: rhythm(1 / 2) }}>
          <GitHubButton githubUsername={githubUsername} />
        </li>
        <li css={{ margin: 0, marginRight: rhythm(1 / 2) }}>
          <TwitterButton twitterUsername={twitterUsername} />
        </li>
        <li css={{ margin: 0 }}>
          <PolyworkButton polyworkUsername={polyworkUsername} />
        </li>
      </ul>
    </div>
  </div>
)

Bio.propTypes = {
  author: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  githubUsername: PropTypes.string.isRequired,
  twitterUsername: PropTypes.string.isRequired,
}

export default Bio
