/** @jsx jsx */
import { jsx } from '@emotion/core'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'

import { rhythm } from '../utils/typography'

const Post = ({ fields, frontmatter, excerpt, timeToRead }) => (
  <Link
    to={fields.slug}
    css={{
      display: 'block',
      paddingTop: rhythm(1 / 2),
      paddingBottom: rhythm(1 / 2),
      borderRadius: 2,
      '&:focus-visible': {
        boxShadow: '0 0 3px 0 rgba(0,0,0,.12)',
      },
    }}
  >
    <h3 css={{ marginTop: 0, marginBottom: rhythm(1 / 4), color: '#007acc' }}>
      {frontmatter.title}
    </h3>

    <p
      css={{ marginBottom: 0, color: '#555' }}
      dangerouslySetInnerHTML={{
        __html: frontmatter.description || excerpt,
      }}
    />

    <small css={{ color: '#777' }}>
      {frontmatter.date} • {timeToRead} min read
    </small>
  </Link>
)

Post.propTypes = {
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
  excerpt: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
}

export default Post
