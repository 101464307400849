/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { graphql } from 'gatsby'

import { rhythm } from '../utils/typography'
import SEO from '../components/SEO'
import DocSearch from '../components/DocSearch'
import Header from '../components/Header'
import Bio from '../components/Bio'
import Post from '../components/Post'
import Footer from '../components/Footer'

function HomePage({ data }) {
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <SEO />

      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          css={{
            zIndex: 4,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            maxWidth: 846,
            height: 48,
            margin: 'auto',
            width: '100%',
            padding: 6,
            marginTop: 8,
          }}
        >
          <DocSearch
            appId={process.env.GATSBY_DOCSEARCH_APP_ID}
            apiKey={process.env.GATSBY_DOCSEARCH_API_KEY}
            indexName={process.env.GATSBY_DOCSEARCH_INDEX_NAME}
          />
        </div>
      </div>

      <Header>
        <Bio {...data.site.siteMetadata} />
      </Header>

      <main
        css={{
          padding: `${rhythm(1)} ${rhythm(1 / 2)}`,
        }}
      >
        <ol
          css={{
            maxWidth: 750,
            margin: 'auto',
            listStyle: 'none',
            '& li:not(:last-of-type)': {
              marginBottom: rhythm(1),
            },
          }}
        >
          {posts.map(({ node }) => (
            <li key={node.fields.slug} css={{ margin: 0 }}>
              <Post {...node} />
            </li>
          ))}
        </ol>
      </main>

      <Footer isHome={true} {...data.site.siteMetadata} />
    </>
  )
}

export default HomePage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        author
        description
        githubUsername
        twitterUsername
        polyworkUsername
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            description
            date(formatString: "MMMM D, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
